<template>
  <div>
    <header-style title="Dashboard" />
    <div class="d-flex dashboard-header justify-content-between mt-2 mx-3">
      <div class=" d-flex py-1 m-2">
        <p class="pt-2">Filters: </p>
        <div class="px-2 ">
          <!-- <b-form-datepicker id="example-datepicker" v-model="value" class=" p-1" text="week"></b-form-datepicker> -->
          <b-dropdown size="" variant="transparent" toggle-class="text-decoration-none"  class="all-project-input">
          <template #button-content class="">
            <i class="fas fa-columns"></i> lastweek
          </template>
          <b-dropdown-item class="items ">Last Week</b-dropdown-item>
          <b-dropdown-item class="items">Last Month</b-dropdown-item>
          <b-dropdown-item class="items">Last Quarter</b-dropdown-item>
          <b-dropdown-item class="items">Customer Range</b-dropdown-item>
  </b-dropdown>
        </div>
        <div class="">
          <b-dropdown id="dropdown-1" class="all-project-input" variant="transparent">
            <template #button-content class="">
            <i class="fab fa-buffer"></i> all Projects
          </template>
            <!-- <b-dropdown-item>First Action</b-dropdown-item>
          <b-dropdown-item>Second Action</b-dropdown-item>
          <b-dropdown-item>Third Action</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item active>Active action</b-dropdown-item>
          <b-dropdown-item disabled>Disabled action</b-dropdown-item> -->
          </b-dropdown>
        </div>
      </div>
      <div class="pt-3 px-3">
        <!-- <b-button variant="green-dark" class="mx-3 filter-dashboard">Apply Filter</b-button> -->
        <b-button variant="blue">
          <i class="fas fa-arrow-circle-down"></i>export</b-button>
      </div>
      </div>
      <div class="card-dashboard-group px-1">
        <div class="card-dashboard">
          <b-card style="background-color: #E7F8F0;" class="p-2">
            <b-card-text class="title-card-dashboard">Success Rate</b-card-text>
            <h4 class="rate-card">95% </h4>
          </b-card>
        </div>
        <div class="card-dashboard">
          <b-card style="background-color: #E7F8F0;" class="p-2">
            <b-card-text class="title-card-dashboard">Projects</b-card-text>
            <h4 class="rate-card">25 </h4>
          </b-card>
        </div>
        <div class="card-dashboard">
          <b-card style="background-color: #E7F8F0;" class="p-2">
            <b-card-text class="title-card-dashboard">Payment Methods</b-card-text>
            <h4 class="rate-card">5 </h4>
          </b-card>
        </div>
        <div class=" card-dashboard">
          <b-card style="background-color: #E7F8F0;" class="p-2">
            <b-card-text class="title-card-dashboard">Customers</b-card-text>
            <h4 class="rate-card">140</h4>
          </b-card>
        </div>
        <div class="card-dashboard">
          <b-card style="background-color: #E7F8F0;" class="p-2">
            <b-card-text class="title-card-dashboard">Merchants</b-card-text>
            <h4 class="rate-card">76</h4>
          </b-card>
        </div>
      </div>
    <div class=" m-3">
      <b-row>
        <b-col v-for="chart in charts" :key="chart.title" xl="4" lg="5" md="12" sm="12" class="chart">
          <iq-card>
            <template v-slot:headerTitle>
              <h5>{{ chart.title }}</h5>
              <div class="d-flex justify-content-between align-items-center">
                <p class="amount-chart">{{ chart.price }} <span class="currency-chart">{{ chart.currency }}</span> </p>
                <p :class="chart.PercentageClass" class="" style="width:75px;height:30px;border-radius:100px;text-align: center; display: flex; align-items: center; justify-content: center;font-size: 13px;font-weight: 600; " > <span><i class="fas fa-arrow-down px-1"></i></span> {{ chart.Percentage }}</p>
              </div>
            </template>
            <template v-slot:body>
              <ApexChart :element="chart.type" :chartOption="chart.bodyData" />
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </div>
    <b-row class="tables "  cols="12">
      <b-col class="table-dashboard" col="6" lg=""  md="12" sm="12">
        <p class="footer-table">Payment Flow</p>
        <main-table
        :fields="fields1"
        :items="items1"
        createRouteName=""
      />
    </b-col>
    <b-col class="table-dashboard" col="6"  lg="" md="12" sm="12">
      <p class="footer-table">Decline Reasons</p>
      <main-table
        :fields="fields2"
        :items="items2"
        createRouteName=""
      />
    </b-col>
    </b-row>
    <div class="m-3">
      <b-row>
        <b-col v-for="chart in charts1" :key="chart.title"  lg="6" md="12" sm="12" class="chart2">
          <iq-card>
            <template v-slot:headerTitle>
              <h5>{{ chart.title }}</h5>
              <p class="amount-chart">{{ chart.price }} <span class="currency-chart">{{ chart.currency }}</span> </p>
            </template>
            <template v-slot:body>
              <ApexChart :element="chart.type" :chartOption="chart.bodyData" />
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import HeaderStyle from '../../components/core/partials/HeaderStyle/HeaderStyle.vue'
import ApexChart from '../../components/core/charts/ApexChart'
export default {
  components: { HeaderStyle, ApexChart },
  name: 'Dashboard',
  data () {
    return {
      fields1: ['method', 'TRX_count', 'Amount_SAR'],
      items1: [
        { method: 'Apple Pay', TRX_count: '10', Amount_SAR: '10,500' },
        { method: 'Google Pay', TRX_count: '5', Amount_SAR: '4,545' },
        { method: 'STC-Pay', TRX_count: '15', Amount_SAR: '150,000' }
      ],
      fields2: [
        { key: 'Reason', label: 'Reason' },
        { key: 'Failed', label: 'Failed', type: 'status' },
        { key: 'Passed', label: 'Passed', type: 'status' },
        { key: 'Percentage', label: 'Percentage' }
      ],
      items2: [
        { Reason: 'Insufficient Fund', Failed: '11', Passed: '20', Percentage: '89%' },
        { Reason: 'Unauthorized', Failed: '5', Passed: '20', Percentage: '95%' },
        { Reason: 'Server Error', Failed: '6', Passed: '20', Percentage: '94%' }
      ],

      charts: [
        {
          title: 'Total Transaction Amount',
          price: '25,550',
          currency: 'SAR',
          Percentage: '25%',
          PercentageClass: 'bg-success',
          type: 'chart-1',
          bodyData: {
            chart: {
              height: 270,
              type: 'area'
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            colors: ['#0084ff', '#00ca00'],
            series: [{
            //   name: 'series1',
            //   data: [31, 40, 28, 51, 42, 109, 100]
            // }, {{
              name: 'Free Cash Flow',
              data: [30, 50, 45, 80, 80, 90]
            }],

            xaxis: {
              categories: ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']
            },
            yaxis: {
              min: 0,
              max: 100
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              }
            }
          }
        },
        {
          title: 'Total Transaction Count',
          price: '145',
          currency: 'TRX',
          Percentage: '11.5%',
          PercentageClass: 'bg-danger',
          type: 'chart-2',
          bodyData: {
            chart: {
              height: 270,
              type: 'area'
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            colors: ['#ffc107', '#00ca00'],
            series: [{
            //   name: 'series1',
            //   data: [31, 40, 28, 51, 42, 109, 100]
            // }, {
            //   name: 'series2',
            //   data: [11, 32, 45, 32, 34, 52, 41]
            // }, {
              name: 'Free Cash Flow',
              data: [40, 43, 50, 40, 50, 70]
            }],

            xaxis: {
              categories: ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              }
            }
          }
        },
        {
          title: 'Total Revenue Amount',
          price: '70,040',
          currency: 'SAR',
          Percentage: '17.35%',
          PercentageClass: 'bg-danger',
          type: 'chart-3',
          bodyData: {
            chart: {
              height: 270,
              type: 'area'
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            colors: ['#0ABA68', '#FFF6E7', '#0ABA68'],
            series: [{
            //   name: 'series1',
            //   data: [31, 40, 28, 51, 42, 109, 100]
            // }, {
              name: 'series2',
              data: [50, 100, 80, 110, 130, 100]
            }],

            xaxis: {
              categories: ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug']
            },
            tooltip: {
              // x: {
              //   format: 'dd/MM/yy HH:mm'
              // }
            }
          }
        }
      ],
      charts1: [
        {
          title: 'Projects',
          type: 'column-1',
          bodyData: {
            chart: {
              height: 350,
              type: 'bar'
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '8%',
                borderRadius: 4
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            colors: ['#0A7EBA'],
            series: [{
              name: 'Net Profit',
              data: [350, 600, 400, 300, 700]
            }
            //  {
            //   name: 'Revenue',
            //   data: [76, 85, 101, 98, 87, 105]
            // }, {
            //   name: 'Free Cash Flow',
            //   data: [35, 41, 36, 26, 45, 48]
            // }
            ],
            xaxis: {
              categories: ['DPA', 'SmartSavings', 'IBBIL', 'OTIX', 'QPR']
            },
            yaxis: {
              title: {
                text: '$ (thousands)'
              }
            },
            fill: {
              opacity: 1

            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return '$ ' + val + ' thousands'
                }
              }
            }
          }
        },
        {
          title: 'Payment Gateway',
          type: 'column-2',
          bodyData: {
            chart: {
              height: 350,
              type: 'bar'
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '8%',
                borderRadius: 4
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['#E7F8F0']
            },
            colors: ['#0ABA68'],
            series: [
            //   {
            //   name: 'Net Profit',
            //   data: [44, 55, 57, 56, 61, 58]
            // },
              {
                name: 'Revenue',
                data: [350, 600, 400, 300, 700]
              } //  {
            //   name: 'Free Cash Flow',
            //   data: [35, 41, 36, 26, 45, 48]
            // }
            ],
            xaxis: {
              categories: ['Hyper Pay', 'Noon Pay', 'Fawry', 'OPay', 'PayMob']
            },
            yaxis: {
              title: {
                text: '$ (thousands)'
              }
            },
            fill: {
              opacity: 1

            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return '$ ' + val + ' thousands'
                }
              }
            }
          }
        }
      ]
    }
  }
}
</script>
<style lang="scss">
.items{
padding-bottom: 5px !important;

width: 100%;
}
.items:hover{
  background-color: transparent !important;
  color: #015426 !important;
}
.footer-table{
  padding-top: 8px !important;
  padding-inline-start: 10px;
  margin: 0px !important;
  padding-bottom: 15px;
  font-size: 16px;
  font-weight: 700;
}
.tables{
  margin: 20px;
  gap: 30px;
}
.table-dashboard{
//  width: 49% !important;
 background-color: white !important;
 border-radius: 8px !important;
// @media (max-width:1200px) {
//   width: 100%;
// }
}
.rate-card{
  color:#0ABA68 ;
  font-weight: 700;
  font-size: 32px;
  padding-inline-start: 10px;
}
.title-card-dashboard{
  color:#015426 !important ;
  font-weight: 600;
  font-size: 16px;
  padding-inline-start: 10px;
}
.header-dashboard{
margin-inline-start: 10px;
}
.card-dashboard {
  width: 18%;
  margin: 14px;
  border-radius: 8px !important;
  margin-bottom: 0px !important;
  @media (max-width: 1200px) {

    width: 40%;
}
}

@media (max-width: 800px) {
  .card-dashboard{
    width: 100%;
  }
}
.card-dashboard-group{
  display: flex;
  flex-wrap: wrap;
}
.filter-dashboard{
 opacity: 0.5;
 width: 109px !important;
 border-radius: 8px !important;
}
.all-project-input{

width: 200px !important;
height: 48px;
}
.currency-chart{
  font-size: 16px;
  font-weight: 400;
}
.amount-chart{
  font-size: 26px;
  font-weight: 700;
  color: black;
}
.dashboard-header{
  background-color: white;
  border-radius:8px !important;
}
/* .all-project-items{
background-color: transparent !important;
} */
</style>
